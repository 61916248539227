import React from "react"
import { Row, Col } from "reactstrap"
import Image from "../image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons"

function Accordian({
  articleHeading,
  html,
  scrollCollapse,
  scrollandCollapse,
}) {
  const [collapse, setCollapse] = React.useState(false)

  const toggleAccordian = () => {
    setCollapse(!collapse)
    scrollandCollapse(false)
  }

  return (
    <Row className="m-0" id="leads-detail">
      <Col xs={12} className="p-0 mt-4 mt-md-0">
        <div
          className="bg-primary text-light px-5 py-4 d-flex align-items-center"
          onClick={toggleAccordian}
          onKeyDown={toggleAccordian}
          role="button"
          tabIndex="0"
        >
          <FontAwesomeIcon
            icon={collapse ? faCaretDown : faCaretRight}
            size="lg"
          />
          &nbsp;&nbsp;
          <h5 className="m-0 p-0">{articleHeading}</h5>
        </div>
        <div
          className={` ml-3 py-3 ${
            collapse || scrollCollapse ? "d-block" : "d-none"
          }`}
        >
          <div className="px-md-5" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </Col>
    </Row>
  )
}

export default Accordian
