import React, { useEffect, useState } from "react"
import { Row, Col, Spinner, Card } from "reactstrap"
import Image from "../image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBook, faFile } from "@fortawesome/free-solid-svg-icons"

function LeadsHeader({
  title,
  scrollDown,
  scrollandCollapse,
  taskId,
  path,
  subArticle,
}) {
  const [iframeLoading, setIframeLoading] = useState(true)
  const [clickId, setClickId] = useState(true)

  let formPath = subArticle ? `/${taskId}` : path
  const handleScroll = () => {
    scrollDown.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    let TempClickId = urlParams.get("rtClickID")
    if (TempClickId) {
      setCookie("rtkclickid-store", TempClickId, 1)
      setClickId(TempClickId)
    } else {
      loadScript()
    }
  }, [])

  const loadScript = () => {
    const script = document.createElement("script")
    script.id = "__script__id__"
    script.type = "text/javascript"
    script.async = true
    script.src =
      "https://track.imgtrx2.com/uniclick.js?defaultcampaignid=6304b38af47fd900011b58e7&attribution=lastpaid&regviewonce=false&cookiedomain=&cookieduration=30"
    document.body.append(script)
    checkCookie()
  }

  const checkCookie = () => {
    var myInterval = setInterval(() => {
      let myCookie = getCookie("rtkclickid-store")
      if (myCookie) {
        console.log("COOKIE RECEIVED")
        setClickId(myCookie)
        console.log("myCookie", myCookie)
        clearInterval(myInterval)
      } else {
        console.log("Cookie is not there")
      }
    }, 2000)
  }

  const getCookie = name => {
    var value = "; " + document.cookie
    var parts = value.split("; " + name + "=")
    if (parts.length == 2) return parts.pop().split(";").shift()
  }

  const setCookie = (name, value) => {
    var expires = ""
    var date = new Date()
    date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toUTCString()
    document.cookie = name + "=" + (value || "") + expires + "; path=/"
  }

  return (
    <div className="landing-container">
      <Row className="px-0 pr-md-5 container mx-auto">
        <Col xs={12} lg={7} className="d-flex align-items-end mt-2">
          <h2 className="text-primary">{title}</h2>
        </Col>
        <Col xs={12} lg={5} className="my-5 order-2 order-lg-1">
          {iframeLoading ? (
            <Card
              className="bg-white position-absolute w-100 d-flex justify-content-center align-items-center"
              style={{ minHeight: 600 }}
            >
              <Spinner
                style={{ width: "2.5rem", height: "2.5rem" }}
                color="primary"
              />
            </Card>
          ) : null}
          <iframe
            id="HA-Frame"
            onLoad={() => setIframeLoading(false)}
            src={`https://forms.yourrepairexperts.com${formPath}?clickId=${clickId}&parent=${formPath}`}
            // src={`http://localhost:3000${formPath}?clickId=${clickId}&parent=${formPath}`}
          />
        </Col>
        <Col xs={12} lg={7} className="order-1 order-lg-2">
          <div className="arrow d-none d-lg-block">
            <h4 className="text-light">FILL OUT A QUICK 2 MINUTE FORM</h4>
          </div>
          <div className="d-flex flex-column align-items-center">
            <div className="p-3  arrow-down d-lg-none">
              <h3 className="text-light text-center">
                FILL OUT A QUICK 2 MINUTE FORM
              </h3>
            </div>
          </div>
          <div className="mb-4">
            <button
              className="font-weight-bold text-primary btn p-0"
              onClick={handleScroll}
            >
              Learn more
            </button>
          </div>
        </Col>

        <Col xs={12} lg={7} className="order-3 order-lg-3">
          <Row>
            <Col xs={11} className="d-flex align-items-center">
              <FontAwesomeIcon icon={faBook} />
              &nbsp;&nbsp;
              <h6 className="m-0">
                Compare Multiple Rates, so you know you’re getting the best
                deal.
              </h6>
            </Col>
            <Col xs={11} className="pt-3 d-flex align-items-center">
              <FontAwesomeIcon icon={faFile} />
              &nbsp;&nbsp;
              <h6 className="m-0">
                Compare quotes on essential services and equipment for your home
                or business.
              </h6>
            </Col>
          </Row>
          <Row className="d-none d-lg-block">
            <Col xs={12} md={11} lg={12} className="d-flex justify-content-end">
              <Image src="guide-arrow.png" style={{ width: 135 }} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default LeadsHeader
