import React from "react"
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faStickyNote,
  faUserFriends,
  faCheck,
} from "@fortawesome/free-solid-svg-icons"

const Steps = () => {
  return (
    <Container>
      <div className="py-5">
        <h2 className="text-center text-dark">
          The easiest way to find services for your home
        </h2>
      </div>
      <Row className="steps-content">
        <Col xs={12} md={4} className="card-w">
          <Card className="mx-3 p-3">
            <CardBody className="text-center d-flex flex-column align-items-center">
              <div className="card-icon">
                <FontAwesomeIcon icon={faStickyNote} />
              </div>
              <CardTitle className="font-weight-bolder">
                Fill our form
              </CardTitle>
              <CardText>
                Tell us just a few basic details about what you’re looking to
                buy. Our quick forms will guide you through the key questions to
                consider.
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={4} className="mt-5 mt-md-0 card-w">
          <Card className="mx-3 p-3">
            <CardBody className="text-center d-flex flex-column align-items-center">
              <div className="card-icon">
                <FontAwesomeIcon icon={faUserFriends} />
              </div>
              <CardTitle className="font-weight-bolder">
                Get connected
              </CardTitle>
              <CardText>
                We’ll introduce you to a few, hand-selected sellers who will
                provide best price quotes and answer any questions you may have.
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={4} className="mt-5 mt-md-0 card-w">
          <Card className="mx-3 p-3">
            <CardBody className="text-center d-flex flex-column align-items-center">
              <div className="card-icon">
                <FontAwesomeIcon icon={faCheck} />
              </div>
              <CardTitle className="font-weight-bolder">Choose best</CardTitle>
              <CardText>
                You’ll compare multiple price quotes so you know you’re getting
                the best deal. Our handy Buyer’s Guides will prep you for the
                ins and outs.
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
export default Steps
