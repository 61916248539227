import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Col, Row } from "reactstrap"
import Image from "../components/image"
import { Header, Accordian } from "../components/leads"
import Steps from "../components/home/steps"
import SEO from "../components/seo"

export default class Template extends React.Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.state = {
      collapse: false,
    }
  }

  componentDidMount = () => {
    this.loadFraudBlocker();
  }

  loadFraudBlocker = () => {
    var s = document.createElement("script"),
      h = document.head
    s.async = 1
    s.src = "https://monitor.fraudblocker.com/fbt.js?sid=bPLUgvrpz2nekrWm8pE2k"
    h.appendChild(s)
  }

  scrollandCollapse = collapse => {
    this.setState({
      ...this.state,
      collapse,
    })
  }
  render() {
    const { pageContext: { subArticle = false } = {} } = this.props
    let frontmatter = {},
      html = "",
      image = ""
    if (this.props.data !== null && this.props.data.markdownRemark !== null) {
      frontmatter = this.props.data.markdownRemark.frontmatter
      image = this.props.data.markdownRemark.frontmatter.image.childImageSharp
      html = this.props.data.markdownRemark.html
    }
    const scrollDown = this.ref
    return (
      <Layout>
        <SEO title={frontmatter.categoryName} />
        <div className="mt-50">
          <Header
            title={frontmatter.title}
            taskId={frontmatter.categoryID}
            path={frontmatter.path}
            scrollDown={scrollDown}
            scrollandCollapse={this.scrollandCollapse}
            subArticle={subArticle}
          />
          <Row style={{ marginTop: -20 }} className="d-none d-lg-block m-0">
            <Col xs={8} className="pt-3 pb-2 d-flex justify-content-center">
              <Image
                fluid={image.fluid}
                style={{ width: 400, marginLeft: -100 }}
              />
            </Col>
          </Row>
          <Steps />
          <div className="mt-5" ref={scrollDown}>
            <Accordian
              scrollandCollapse={this.scrollandCollapse}
              scrollCollapse={this.state.collapse}
              html={html}
              articleHeading={frontmatter.articleHeading}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        categoryName
        title
        articleHeading
        categoryID
        image {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
